module.exports = {
    translation: {
        general: {
            unexpectedError: "Beklenmedik bir hata oluştu 🙄",
            guest: "Misafir",
            tokenError: "Oturumunuz sonlandırılmıştır, lütfen tekrar giriş yapın."
        },
        header: {
            dashboard: "ANA SAYFA",
            access: "ERİŞİM TALİMATLARI",
            idDocuments: "KİMLİK BELGELERİ",
            contact: "İLETİŞİM",
            logout: "ÇIKIŞ YAP"
        },
        login: {
            booking: {
                notfound: "Rezervasyon numarası bulunamadı",
                cancelled: "Bu rezervasyon iptal edildi.",
                past: "Bu rezervasyon tamamlanmış olduğu için giriş yapamazsınız."
            },
            title: "<span>REZERVASYON</span> YÖNETİMİ",
            description: "Rezervasyon detaylarını görüntüleyebilmek için bilgileri girin",
            placeholder: "Airbnb onay kodu",
            submit: "GİRİŞ"
        },
        form: {
            noChange: "Kaydedilecek bir değişiklik yok 🙄",
            noGuest: "Kaydedilecek bir ziyaretçi yok 🙄",
            firstNameError : "İsim alanı gereklidir.",
            lastNameError: "Soyisim alanı gerekli: ",
            phoneError: "Telefon Numarası alanı gerekli: ",
            whatsappError: "WhatsApp Telefon numarası alanı gerekli: ",
            emailError: "Email alanı gerekli: ",
            emailValidError: "Geçerli bir mail adresi girmelisiniz: ",
            phoneValidError: "Geçerli bir telefon numarası girmelisiniz ",
            whatsappValidError: "Geçerli bir whatsapp telefon numarası girmelisiniz ",
            passportError: "Pasaport için belge yüklemelisiniz: ",
            checkinError: "Giriş saati alanı için seçim yapılmalı: ",
            checkoutError: "Çıkış saati alanı için seçim yapılmalı: ",
            unexpectedError: "Belge yüklenirken beklenmedik bir hata oluştu: ",
            unexpectedError2: "Misafir kaydedilirken beklenmedik bir hata oluştu: ",
            success: "Form başarılı bir şekilde kaydedildi 👍",
            reservation: "Rezervasyon",
            address: "Adres",
            flat: "Daire",
            arrival: "Giriş",
            departure: "Çıkış",
            title: "MİSAFİRLER",
            description: "Türkiye'de, konaklamanız başlamadan önce <span class='textBackground'>kimliğinizi</span> ve <span class='textBackground'>sizinle</span> <span class='textBackground'>birlikte</span> <span class='textBackground'>kalan</span> <span class='textBackground'>tüm</span> <span class='textBackground'>kişilerin</span> kimliklerini ibraz etmeniz <span class='textBackground'>yasa</span> <span class='textBackground'>gereği</span> zorunludur.",
            descriptionSmall: "Kimlik belgeleriniz misafir ekibimiz tarafından onaylandıktan sonra rezervasyon bilgilerinize erişebileceksiniz.",
            descriptionSubmitted: "Konuk listenizi başarıyla kaydettiniz, şu anda doğrulama aşamasındadır. KAYDET'e tıklayarak istediğiniz zaman misafir ekleyebilirsiniz.",
            addGuest: "MİSAFİR EKLE",
            submit: "KAYDET",
            bottom: "Herhangi bir sorunuz varsa, lütfen bize ulaşın: <a href=\"mailto:reservations@staywithalfred.com\">reservations@staywithalfred.com</a>",
        },
        guest: {
            firstName: "İsim",
            lastName: "Soyisim",
            phone: "Telefon Numarası",
            phonePlaceholder: "örn. +1234567890",
            whatsapp: "WhatsApp Telefon Numarası",
            whatsappPlaceholder: "örn. +1234567890",
            email: "Email",
            upload: "PASAPORT YÜKLE",
            selectedDocument: "Seçilen dosya:",
            mainGuest: "Ana misafir",
            guest: "Misafir",
            checkin: "Giriş saati",
            checkout: "Çıkış saati"
        },
        submitted: {
            title: "<span>KİMLİĞİNİZ</span> DOĞRULANMA AŞAMASINDA",
            description: "Kimlik belgeleriniz misafir ekibimiz tarafından onaylandıktan sonra rezervasyon sayfanıza erişebileceksiniz.",
        },
        details: {
            title: "Merhaba",
            description: "<span class=\"textBackground\">Kimlik</span> <span class=\"textBackground\">belgeleriniz</span> doğrulandı, konaklama detaylarınızı buradan görüntüleyebilirsiniz.",
            access: "ERİŞİM TALİMATLARI",
            reservation: "Rezervasyon",
            address: "Adres",
            flat: "Daire",
            arrival: "Giriş",
            departure: "Çıkış",
            alacati: {
                address: "Alaçatı Taş Evleri",
                content: `<div class="content-wrapper">
                    <div class="textContainer" style="text-align: left;">
                        <div class="subtitle">
                            İki muhteşem 130 yıllık taş villamızda lüks ve gelenek dünyasına kaçın.
                        </div>
                        <p>
                            İlk villa geniş bir yatak odası, tam donanımlı bir mutfak ve şirin bir çalışma odasına sahiptir. İkinci villa ise üç zarif yatak odası, üç lüks banyo, rahat bir oturma odası, iki yemek alanı ve tam donanımlı bir mutfak sunmaktadır.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <img src="/img/20220621_ErdemHamza_Reisdere66306.jpg" alt="Place detail" />
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <div class="subtitle">
                            Her iki villa da ünlü bir mimar tarafından güzel bir şekilde yenilenmiş olup, orijinal binanın tarihi cazibesini modern konfor ve kolaylıkla birleştiriyor.
                        </div>
                        <p>
                            Bu büyüleyici taş evin içine adım attığınızda, kırsal şıklık ve modern lüksün uyumlu birleşimine hayran kalacaksınız. Doğal taş duvarlar, sıcak ahşap zeminler ve şık, yüksek kaliteli mobilyalarla süslenmiş mekan, her mevsimde en üst düzeyde konforunuzu sağlayan şimşek hızında sınırsız internet, merkezi klima ve yerden ısıtma gibi son teknoloji olanaklarla donatılmıştır.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere66351.jpg" alt="Place detail" />
                        </div>
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere66347.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <p>
                            Geniş, açık konsept mutfak, mutfak tutkunlarının hayalidir. Son teknoloji ürünü ev aletleri ve göz alıcı Le Creuset mutfak eşyaları ve aletleriyle süslenmiş bu işlevsel ve şık mekan, içindeki şefi serbest bırakmanız için sizi davet ediyor. Zengin ahşap dolaplar ve parıldayan mermer tezgahlar, bol depolama alanı ve çalışma alanı sağlarken, büyük ve davetkar bir ada, arkadaşlarınız ve ailenizle bir araya gelmek için mükemmel bir mekan sunar.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <div>
                            <img src="/img/20220621_ErdemHamza_Reisdere65976.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <p>
                            Doğal ışık, sayısız pencereden süzülerek evin samimi atmosferini artırır. Pelüş oturma alanları ve gösterişli bir şömineye sahip olan oturma alanı, uzun bir günün ardından rahatlamak için ideal bir yerdir. Sınırsız sıcak su temini, yağmur duşları ve büyük küvetle (ebeveyn odası) donatılmış lüks spa benzeri banyolarda şımartıcı bir deneyim sunar.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere65914.jpg" alt="Place detail" />
                        </div>
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere6636.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <div>
                            <p>
                                Evin içinde, zevkli dekor ve ince eşyalar, etkileyici taş mimariyi başarıyla tamamlar ve sizi evinizde hissettiren huzurlu ve sofistike bir sığınak yaratır.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="imageContainer noBottomMargin">
                    <img src="/img/20220621_ErdemHamza_Reisdere66133.jpg" alt="Place detail" />
                </div>
                </div>`
            },
            no28: {
                address: "No.28 Art Deco",
                content: `<div class="content-wrapper">
                    <div class="textContainer">
                        İkonik Galata Kulesi'nin yakınındaki Art Deco esintili 2+1 No28'de kendinizi caz çağının ihtişamına bırakın.
                    </div> 
                    <div class="imageContainer">
                        <div>
                            <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39388.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer">
                        Ünlü mimar Erdem Hamza tarafından tasarlanan bu zarif mabet, zengin mobilyalara ve 1920'lerin büyüleyici cazibesine sahiptir. 
                    </div> 
                    <div class="imageContainer">
                        <div>
                            <img class="double" src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39408.jpg" alt="Place detail" />
                        </div>
                        <div>
                            <img class="double" src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39480.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer">
                        İstanbul'un kültür sahnesinin kalbinde yer alan bu daire, canlı kafelere, galerilere ve tarihi simge yapılara birkaç adım uzaklıktadır. 
                    </div> 
                    <div class="imageContainer">
                        <div>
                            <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39516.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer">
                        Bu şık inziva yerinde İstanbul'un büyüleyici ruhunun tadını çıkarırken, geçmiş bir dönemin cazibesini ve sofistikeliğini yaşayın.
                    </div>
                </div>
                <div class="imageContainer noBottomMargin">
                    <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39431.jpg" alt="Place detail" />
                </div>
                </div>`
            }
        },
        access: {
            alacati: {
                content: `<div class="content-wrapper">
                    <div class="textContainer">
                        Bunlar evin tüm talimatlarıdır, lütfen villaya girmeden önce bunları okuyabilirseniz harika olur, çünkü geceleri büyük şemsiyeyi kesinlikle kapatmak gibi bazı önemli notlar vardır.
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            ERİŞİM
                        </div>
                        <p>
                            Tam konum:<br />
                            <a href="https://goo.gl/maps/g6oG2g19qgNC49WSA" target="_blank">https://goo.gl/maps/g6oG2g19qgNC49WSA</a>
                        </p>
                        <p>
                            Bej renkli bir otoparkın çift kapısının önüne geleceksiniz. Bizim bahçıvan Murat Bey sizin için kapıyı açacak ya da ben anahtarları bahçenin içine bırakacağım ve uzaktan erişimimden sizin için kapıyı açacağım.
                        </p>
                        <p>
                            WhatsApp <a href="https://api.whatsapp.com/send?phone=905322184942" target="_blank" rel="noreferrer">+905322184942</a>'den 24/24 ulaşılabilirim, lütfen gelmeden yaklaşık 1 saat önce bana mesaj gönderebilirseniz harika olur.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <img src="/img/access-alacati.jpg" alt="Place detail" />
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Garaj Kapısı
                        </div>
                        <p>
                            Otomatik garaj kapısı sadece size verilen 2 siyah uzaktan kumanda ile açılabilir. Kırılacağı için elle itmeye çalışmayın.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Anahtarlar
                        </div>
                        <p>
                            3 anahtarınız var, ilk ikisi geldiğinizde soldaki küçük ev için, sonuncusu ana ev için.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Wifi
                        </div>
                        <p>
                            İsim: EREN-REISDERE<br />
                            Şifre: 1a2b3c4d5e6f
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            KLİMA
                        </div>
                        <p>
                            Elektrik kesilirse, ki bu bölgede sık sık olur, klima üniteleri ısıtma moduna geri dönecektir. Çalışma modunu kar tanesine benzeyen simgeye geri döndürmek için, <b>"OPER MODE"</b> üzerine bir kaç kez dokunmanız gerekecektir.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Şömine
                        </div>
                        <p>
                            Şömine kullanılması kesinlikle yasaktır, sadece dekoratif amaçlıdır.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Tuvaletler
                        </div>
                        <p>
                            Tuvaletlere kağıt atmayın, her tuvaletin yanında bir çöp kutusu vardır, lütfen çöp kutusunu kullanın.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Büyük Şemsiye
                        </div>
                        <ul>
                            <li>Şemsiyeyi açmak için kumaşı tutmanız ve kolu kullanmadan dış tarafa doğru kaldırmaya başlamanız gerekir. Kumaş kaldırıldıktan sonra, şemsiyedeki küçük metal parça kilitlenene kadar yukarı doğru itmek için kolu kullanabilirsiniz.</li>
                            <li>Kapatmak için, küçük metal parçanın kilidini tutamaktan açmanız ve hafifçe yukarı itmeniz gerekir, ardından kendiliğinden aşağı inecektir.</li>
                            <li>Lütfen şemsiyeyi şiddetli rüzgarlarda veya gece kırılacağı için asla açık bırakmayın.</li>
                        </ul>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Dış Mekan Kameraları
                        </div>
                        <p>
                            Herhangi bir olay rapor edilmediği takdirde kayıtları 7 gün sonra otomatik olarak silinen 4 adet dış mekan kamerası bulunmaktadır. Güvenlik ve sigorta amaçlı kullanılırlar.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Dış Işıklar
                        </div>
                        <p>
                            Bahçe ışıkları akşam 8 civarında otomatik olarak yanar. Ancak bunlar sadece birkaç tanesidir. Tüm bahçe ışıklarını yakmak için ana eve girdiğinizde sağ tarafınızdaki banyonun önündeki duvarda bulunan yuvarlak dairesel düğmeye basmanız gerekmektedir.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Yedek Havlu ve Çarşaflar
                        </div>
                        <p>
                            Tüm yedek ihtiyaçlar, mutfağın bitişiğindeki yemek masasının merdivenlerinden sonraki boş alanda bulunan gardıropta saklanmaktadır.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Su / Gaz İkmali
                        </div>
                        <p>
                            Su makinesinin altındaki büyük su şişesinin veya SMEG ocağın altındaki gaz tüpünün boş olması durumunda <b>Kaya Market</b> <a href="tel:+902327170423">+902327170423</a> numaralı telefonu arayabilirsiniz, sizin için teslim edeceklerdir. Faturanın ev sahibi Emrah Yüceer'e eklenmesini talep edebilirsiniz.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            İletişim
                        </div>
                        <p>
                            Burada listelenmeyen başka bir şeye ihtiyacınız olursa, günün veya gecenin herhangi bir saatinde <a href="tel:+90 532 218 49 42">+90 532 218 49 42</a> numaralı telefondan bana ulaşabilirsiniz.
                        </p>
                        <p>
                            Umarım burada geçirdiğiniz zamandan keyif alırsınız.
                        </p>
                        <p>
                            Emrah Yüceer<br />
                            <a href="https://www.instagram.com/staywithalfred/" rel="noreferrer" target="_blank">@staywithalfred</a>
                        </p>
                    </div>
                </div>
                <div class="imageContainer" style="margin-bottom: 0;">
                    <img src="/img/20220621_ErdemHamza_Reisdere66235.jpg" alt="Place detail" />
                </div>`
            },
            no28: {
                content: `<div class="content-wrapper">
                    <div class="textContainer">
                        Kiralamanızla ilgili tüm talimatlar - içeri girmeden önce bunları okuyabilirseniz harika olur!
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Erişim
                        </div>
                        <p>
                            Google Haritalar konumu:<br />
                            <a href="https://maps.app.goo.gl/EoYg9gzjD3VXB8AA7?g_st=ic" target="_blank">https://maps.app.goo.gl/EoYg9gzjD3VXB8AA7?g_st=ic</a>
                        </p>
                        <p>
                            Taksi çağırmak istediğinizde kullanacağınız adres:<br />
                            Tomtom, Kumbaracı Ykş. No:28, 34433 Beyoğlu, İstanbul.
                        </p>
                        <p>
                            Bu bina girişi, sokak tarafındaki kapıyı geçip ilk sağa dönmeniz gerekiyor:
                        </p>
                        <div class="imageContainer noRatio">
                            <img src="/img/access-no28-Photo1-1.jpg" alt="Place detail" />
                        </div>
                        <p>
                            Sonra 4. kata çıkmanız gerekiyor ve sol tarafınızda daha güzel bir kapı göreceksiniz. Bu benim dairemin kapısı. <br /> 
                            Merdivenlerdeki aydınlatma yanmadan önce birkaç basamak çıkmanız gerekiyor.
                        </p>
                        <div class="imageContainer noRatio">
                            <img src="/img/access-no28-Photo2.jpg" alt="Place detail" />
                        </div>
                        <p>
                            Anahtarlar aşağıdaki kutunun içinde olacaktır:
                        </p>
                        <div class="imageContainer noRatio">
                            <img src="/img/keys_photo.jpg" alt="Place detail" />
                        </div>
                        <p>
                            Kutu açmanız için gereken şifre 1107.
                        </p>
                        <p>
                            Anahtarları aldıktan sonra, kutuyu kapatmak için rastgele bir şifre girin.
                        </p>
                        <p>
                            Lütfen bu binada sadece yerel ailelerin yaşadığını unutmayın, bagajları taşırken mümkün olduğunca az gürültü yaparsanız bu harika olur, böylece kimse bana şikayet etmez.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Anahtarlar
                        </div>
                        <p>
                            2 çift anahtarınız var, lütfen ayrılırken ikisini de masanın üzerine bırakın.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Wifi
                        </div>
                        <p>
                            İsim: FiberHGW_ZTAF2A<br />
                            Şifre: zFDpdjuzDu
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Çöp
                        </div>
                        <p>
                            Gece geç saatlerde veya sabah erken saatlerde binanın önüne bırakabilirsiniz, gündüz servisi alacaktır.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            İletişim
                        </div>
                        <p>
                            Burada listelenmeyen başka bir şeye ihtiyacınız olursa, günün veya gecenin herhangi bir saatinde <a href="tel:+90 532 218 49 42">+90 532 218 49 42</a> numaralı telefondan bana ulaşmaktan çekinmeyin.
                        </p>
                        <p>
                            Umarım burada geçireceğiniz zamandan keyif alırsınız.
                        </p>
                        <p>
                            Emrah Yüceer<br />
                            <a href="https://www.instagram.com/staywithalfred/" rel="noreferrer" target="_blank">@staywithalfred</a>
                        </p>
                    </div>
                </div>
                <div class="imageContainer" style="margin-bottom: 0;">
                    <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39516.jpg" alt="Place detail" />
                </div>`
            }
        }

    }
}