import './HomeDetails.page.css';
import {Circles} from "react-loader-spinner";
import SubmitButton from "../../components/submit-button/SubmitButton";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function generateGuestName(bookingData, guestName = 'Guest') {
    let names = [];
    if(bookingData && bookingData.guests && bookingData.guests.length > 0) {
        let mainGuest = bookingData.guests[0];
        if(mainGuest.firstname) names.push(mainGuest.firstname);
        if(mainGuest.lastname) names.push(mainGuest.lastname);
    }
    if(names.length > 0) {
        guestName = names.join(' ');
    }
    return guestName;
}

function HomeDetailsPage({bookingData = null}) {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const formatter = new Intl.DateTimeFormat('en', { month: 'short' });
    const dayFormatter = new Intl.DateTimeFormat('en', { day: '2-digit' });

    return (
        !bookingData ? <div className={"pageLoader"}><Circles color={"#FCF8EC"} visible={true} /></div> : <div className={"homeDetailsPage"}>
            <section className={"bannerSection"}>
                <img src={bookingData.housing.airbnbName !== 'No.28 Art Deco: Chic 2+1 Galata' ? "/img/20220621_ErdemHamza_Reisdere66118.jpg" : "/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39372.jpg"} alt={"Place"} />
                <div className={"content-wrapper infoBoxContainer"}>
                    <div className={"infoBox"}>
                        <div className={"infoBoxTop"}>
                            <div className={"infoBoxTexts"}>
                                <div className={"titleText"}><span>{t('details.title')}</span>{generateGuestName(bookingData, t('general.guest'))}</div>
                                <div className={"description"} dangerouslySetInnerHTML={{__html: t('details.description')}}>

                                </div>
                            </div>
                            <SubmitButton text={t('details.access')} onClick={() => {
                                navigate('/access');
                            }} />
                        </div>
                        <div className={"infoBoxFields"}>
                            <div className={"item"}>
                                <div className={"title"}>{t('details.reservation')}</div>
                                <div className={"content"}>{bookingData.bookingNumber}</div>
                            </div>
                            <div className={"item"}>
                                <div className={"title"}>{t('details.flat')}</div>
                                <div className={"content"}>{bookingData.housing.airbnbName !== 'No.28 Art Deco: Chic 2+1 Galata' ? t('details.alacati.address') : t('details.no28.address')}</div>
                            </div>
                            <div className={"item-double"}>
                                <div className={"item"}>
                                    <div className={"title"}>{t('details.arrival')}</div>
                                    <div className={"content"}>{formatter.format(new Date(bookingData.fromDate))}. {dayFormatter.format(new Date(bookingData.fromDate))}</div>
                                </div>
                                <div className={"item"}>
                                    <div className={"title"}>{t('details.departure')}</div>
                                    <div className={"content"}>{formatter.format(new Date(bookingData.toDate))}. {dayFormatter.format(new Date(bookingData.toDate))}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={"contentSection noBottomPadding"} dangerouslySetInnerHTML={{__html: bookingData.housing.airbnbName !== 'No.28 Art Deco: Chic 2+1 Galata' ? t('details.alacati.content') : t('details.no28.content')}}>

            </section>
        </div>
    );

}

export default HomeDetailsPage;