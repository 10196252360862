import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { pdfjs } from 'react-pdf';



import './i18n';
import ScrollToTop from './components/scroll-top-top/scrollToTop';

pdfjs.GlobalWorkerOptions.workerSrc = window.pdfJsWorker;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop />
            <App />
        </BrowserRouter>
    </React.StrictMode>
);
