module.exports = {
    translation: {
        "general":{
            "unexpectedError":"Une erreur inattendue s'est produite 🙄",
            "guest":"Invité",
            "tokenError": "Votre session a été interrompue, veuillez vous connecter à nouveau."
        },
        "header":{
            "dashboard":"ACCUEIL",
            "access":"INSTRUCTION D'ACCES",
            "idDocuments":"PIÈCES D'IDENTITÉ",
            "contact":"CONTACT",
            "logout": "LOGOUT"
        },
        "login":{
            "booking":{
                "notfound":"Numéro de réservation introuvable",
                "cancelled": "Cette réservation a été annulée.",
                "past": "Vous ne pouvez pas vous connecter car cette réservation a été effectuée."
            },
            "title":"<span>GÉREZ VOTRE</span> RÉSERVATION",
            "description":"Entrez vos informations pour afficher les détails de la réservation",
            "placeholder":"Code de confirmation Airbnb",
            "submit":"CONNEXION"
        },
        "form":{
            "noChange":"Aucune modification à enregistrer 🙄",
            "noGuest":"Aucun invité à enregistrer 🙄",
            "firstNameError":"Le prénom est requis.",
            "lastNameError":"Le nom de famille est requis : ",
            "phoneError":"Le numéro de téléphone est requis : ",
            "whatsappError":"Le numéro de téléphone WhatsApp est requis : ",
            "emailError":"L'adresse e-mail est requise : ",
            "emailValidError":"Veuillez entrer une adresse e-mail valide : ",
            "phoneValidError":"Veuillez entrer un numéro de téléphone valide ",
            "whatsappValidError":"Veuillez entrer un numéro de téléphone WhatsApp valide ",
            "passportError":"Vous devez télécharger un document pour le passeport : ",
            "checkinError": "Vous devez sélectionner l'heure d'arrivée de l'invité : ",
            "checkoutError": "Vous devez sélectionner l'heure de départ de l'invité : ",
            "unexpectedError":"Une erreur inattendue s'est produite lors du téléchargement du document : ",
            "unexpectedError2":"Une erreur inattendue s'est produite lors de l'enregistrement de l'invité : ",
            "success":"Le formulaire a été enregistré avec succès 👍",
            "reservation":"Réservation",
            "address":"Adresse",
            "flat": "Appartement",
            "arrival":"Arrivée",
            "departure":"Départ",
            "title":"CONVIVES",
            "description":"En Turquie, il est <span class='textBackground'>obligatoire</span> de présenter <span class='textBackground'>votre</span> <span class='textBackground'>pièce</span> <span class='textBackground'>d'identité</span> et celles de <span class='textBackground'>toutes</span> <span class='textBackground'>les</span> <span class='textBackground'>personnes</span> <span class='textBackground'>qui</span> <span class='textBackground'>vous</span> <span class='textBackground'>accompagnent</span> avant le début de votre séjour.",
            "descriptionSmall":"Une fois vos pièces d'identité vérifiées par notre équipe, vous pourrez accéder aux informations de réservation.",
            "descriptionSubmitted":"Vous avez enregistré votre liste d'invités avec succès. Elle est actuellement en cours de vérification. Vous pouvez ajouter des invités à tout moment en cliquant sur ENREGISTRER.",
            "addGuest":"AJOUTER UN INVITÉ",
            "submit":"ENREGISTRER",
            "bottom":"Si vous avez des questions, veuillez nous contacter à l'adresse : <a href=\"mailto:reservations@staywithalfred.com\">reservations@staywithalfred.com</a>"
        },
        "guest":{
            "firstName":"Prénom",
            "lastName":"Nom de famille",
            "phone":"Numéro de téléphone",
            "phonePlaceholder":"ex. +1234567890",
            "whatsapp":"Numéro de téléphone WhatsApp",
            "whatsappPlaceholder":"ex. +1234567890",
            "email":"E-mail",
            "upload":"TÉLÉCHARGER LE PASSEPORT",
            "selectedDocument":"Fichier sélectionné :",
            "mainGuest":"Invité principal",
            "guest":"Invité",
            "checkin": "Heure d'arrivée",
            "checkout": "Heure de départ"
        },
        "submitted":{
            "title":"<span>VOTRE IDENTITÉ</span> EST EN COURS DE VÉRIFICATION",
            "description":"Une fois vos pièces d'identité vérifiées par notre équipe, vous pourrez accéder à la page de réservation."
        },
        "details":{
            "title":"Bonjour",
            "description":"Vos <span class=\"textBackground\">pièces d'identité</span> ont été vérifiées. Vous pouvez consulter les détails de votre séjour ici.",
            "access":"INSTRUCTION D'ACCES",
            "reservation":"Réservation",
            "address":"Adresse",
            "flat": "Appartement",
            "arrival":"Arrivée",
            "departure":"Départ",
            alacati: {
                address: "Alaçatı Taş Evleri",
                content: `<div class="content-wrapper">
                    <div class="textContainer" style="text-align: left;">
                        <div class="subtitle">
                            Évadez-vous dans un monde de luxe et de tradition dans nos 2 magnifiques villas vieilles de 130 ans.
                        </div>
                        <p>
                            La première villa dispose d'une chambre spacieuse, d'une cuisine équipée et d'un bureau confortable. La seconde villa offre 3 chambres élégantes, 3 salles de bains luxueuses, un salon confortable, 2 salles à manger et une cuisine entièrement équipée.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <img src="/img/20220621_ErdemHamza_Reisdere66306.jpg" alt="Place detail" />
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <div class="subtitle">
                            Les 2 villas ont été magnifiquement rénovées par un architecte de renom et allient le charme historique du bâtiment d'origine au confort moderne.
                        </div>
                        <p>
                            Entrez dans cette charmante maison en pierre, où le charme rustique rencontre le luxe moderne. Dès l'entrée, vous serez immédiatement séduit par le mélange harmonieux de murs en pierre naturelle, de parquet chaleureux et d'un mobilier élégant et de grande qualité. La maison est dotée d'un ensemble d'équipements de pointe, dont une connexion Internet rapide et illimitée, la climatisation centralisée et le chauffage au sol, qui garantissent votre confort en toute saison.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere66351.jpg" alt="Place detail" />
                        </div>
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere66347.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <p>
                            La cuisine spacieuse et ouverte est un rêve pour les amateurs de cuisine. Équipée d'appareils électroménagers ultramodernes et ornée d'ustensiles de cuisine Le Creuset, cette pièce fonctionnelle et élégante vous invite à laisser libre cours au chef qui sommeille en vous. De riches armoires en bois et des comptoirs en marbre étincelant offrent un vaste espace de rangement et de travail, tandis qu'un grand îlot accueillant sert de lieu de rassemblement idéal pour les amis et la famille.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <div>
                            <img src="/img/20220621_ErdemHamza_Reisdere65976.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <p>
                            La lumière naturelle filtre à travers les nombreuses fenêtres, renforçant l'ambiance chaleureuse de la maison. L'espace de vie, doté de fauteuils confortables et d'une cheminée, est l'endroit idéal pour se détendre après une longue journée. L'approvisionnement illimité en eau chaude garantit une expérience agréable dans les luxueuses salles de bains, dotées de douches à effet de pluie et d'une baignoire dans la chambre principale.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere65914.jpg" alt="Place detail" />
                        </div>
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere6636.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <div>
                            <p>
                                Dans toute la maison, une décoration de bon goût et des objets uniques complètent sans effort la superbe architecture en pierre, créant une retraite sereine et sophistiquée qui vous invite à vous sentir comme chez vous.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="imageContainer noBottomMargin">
                    <img src="/img/20220621_ErdemHamza_Reisdere66133.jpg" alt="Place detail" />
                </div>
                </div>`
            },
            no28: {
                address: "No.28 Art Deco",
                content: `<div class="content-wrapper">
                    <div class="textContainer">
                        Plongez dans le glamour de l'ère du jazz au No.28, un appartement 2+1 d'inspiration Art déco situé à proximité de l'emblématique tour de Galata.
                    </div> 
                    <div class="imageContainer">
                        <div>
                            <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39388.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer">
                        Conçu par l'architecte de renom Erdem Hamza, cet élégant sanctuaire présente un mobilier opulent et un charme captivant des années 1920. 
                    </div> 
                    <div class="imageContainer">
                        <div>
                            <img class="double" src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39408.jpg" alt="Place detail" />
                        </div>
                        <div>
                            <img class="double" src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39480.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer">
                        Situé au cœur de la scène culturelle d'Istanbul, il se trouve à quelques pas de cafés animés, de galeries et de sites historiques. 
                    </div> 
                    <div class="imageContainer">
                        <div>
                            <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39516.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer">
                        Découvrez l'allure et la sophistication d'une époque révolue dans ce petit nid.
                    </div>
                </div>
                <div class="imageContainer noBottomMargin">
                    <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39431.jpg" alt="Place detail" />
                </div>
                </div>`
            }
        },
        access: {
            alacati: {
                content: `<div class="content-wrapper">
                    <div class="textContainer">
                        Ce sont les instructions complètes pour la maison, si vous pouviez les lire avant d'entrer dans la villa ce serait bien car il y a quelques notes importantes comme fermer absolument le grand parapluie la nuit car les vents forts peuvent le casser.
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            ACCES
                        </div>
                        <p>
                            Voici l'emplacement exact :<br />
                            <a href="https://goo.gl/maps/g6oG2g19qgNC49WSA" target="_blank">https://goo.gl/maps/g6oG2g19qgNC49WSA</a>
                        </p>
                        <p>
                            Vous arriverez devant une double porte de parking beige, et c'est là que se trouve l'endroit. Soit Mr Murat le jardinier vous ouvrira la porte, soit je laisserai les clés à l'intérieur du jardin et je vous ouvrirai la porte à partir de mon accès à distance.
                        </p>
                        <p>
                            Je suis disponible 24/24 sur WhatsApp <a href="https://api.whatsapp.com/send?phone=905322184942" target="_blank" rel="noreferrer">+905322184942</a>, veuillez bien voulor m'envoyer un message environ 1 heure avant votre arrivée.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <img src="/img/access-alacati.jpg" alt="Place detail" />
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Porte De Garage
                        </div>
                        <p>
                            La porte de garage automatique ne peut être ouverte qu'avec les 2 télécommandes noires qui vous ont été fournies. N'essayez pas de la pousser manuellement car elle se briserait.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Cles
                        </div>
                        <p>
                            Vous avez 3 clés, les deux premières sont pour la petite maison à gauche en arrivant, la dernière est pour la maison principale.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            WIFI
                        </div>
                        <p>
                            Nom : EREN-REISDERE<br />
                            Mot de passe : 1a2b3c4d5e6f
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            CLIMATISATION
                        </div>
                        <p>
                            En cas de coupure de courant, ce qui arrive souvent dans cette région, les climatiseurs repassent en mode chauffage. Vous devrez appuyer sur <b>"OPER MODE"</b> pour revenir à l'icône qui ressemble à un flocon de neige.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            CHEMINES
                        </div>
                        <p>
                            Il est absolument interdit d'utiliser les cheminées, elles sont uniquement décoratives.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            TOILETTES
                        </div>
                        <p>
                            Ne pas mettre de papier dans les toilettes, il y a une poubelle à côté de chaque toilette, merci d'utiliser la poubelle.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            GRAND PARASOL
                        </div>
                        <ul>
                            <li>Pour ouvrir le parasol, vous devez saisir la toile et commencer à la soulever vers l'extérieur, sans utiliser la poignée. Une fois le tissu soulevé, vous pouvez utiliser la poignée pour le pousser vers le haut jusqu'à ce que la petite pièce métallique du parasol soit verrouillée. </li>
                            <li>Pour le fermer, vous devez déverrouiller la petite partie métallique de la poignée et la pousser légèrement vers le haut, puis elle s'abaissera d'elle-même.</li>
                            <li>Ne laissez jamais le parasol ouvert en cas de vent fort ou la nuit, car il se briserait.</li>
                        </ul>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            CAMERAS EXTERIEURES
                        </div>
                        <p>
                            Il y a 4 caméras extérieures dont les enregistrements sont automatiquement effacés après 7 jours si aucun incident n'est signalé. Elles sont utilisées à des fins de sécurité et d'assurance.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            LUMIERES EXTERIEURES
                        </div>
                        <p>
                            Les lumières du jardin s'allument automatiquement vers 20 heures. Cependant, il ne s'agit que de quelques-unes d'entre elles. Pour allumer toutes les lumières du jardin, vous devez appuyer sur le bouton circulaire qui se trouve sur le mur en face de la salle de bain à votre droite lorsque vous entrez dans la maison principale.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            SERVIETTES ET DRAPS DE RECHANGE
                        </div>
                        <p>
                            Toutes les serviettes et les draps de rechange sont rangés dans l'armoire située dans l'espace vide après l'escalier de la table à manger adjacente à la cuisine.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            Réapprovisionnement en eau et en gaz
                        </div>
                        <p>
                            Si la grande bouteille d'eau sous le distributeur d'eau est vide ou le tuyau de gaz sous le réchaud SMEG, envoyez-moi un message sur WhatsApp et je les ferai remplacer.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            CONTACT
                        </div>
                        <p>
                            Si vous avez besoin de quoi que ce soit d'autre qui ne soit pas mentionné ici, n'hésitez pas à me contacter au <a href="tel:+90 532 218 49 42">+90 532 218 49 42</a>, à toute heure du jour ou de la nuit.
                        </p>
                        <p>
                            J'espère que vous apprécierez votre séjour ici.
                        </p>
                        <p>
                            Emrah Yüceer<br />
                            <a href="https://www.instagram.com/staywithalfred/" rel="noreferrer" target="_blank">@staywithalfred</a>
                        </p>
                    </div>
                </div>
                <div class="imageContainer" style="margin-bottom: 0;">
                    <img src="/img/20220621_ErdemHamza_Reisdere66235.jpg" alt="Place detail" />
                </div>`
            },
            no28: {
                content: `<div class="content-wrapper">
                    <div class="textContainer">
                        Voici les instructions complètes pour votre location, si vous pouviez les lire avant d'entrer, ce serait formidable !
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            ACCES
                        </div>
                        <p>
                            Voici l'emplacement exact sur Google Maps :<br />
                            <a href="https://maps.app.goo.gl/EoYg9gzjD3VXB8AA7?g_st=ic" target="_blank">https://maps.app.goo.gl/EoYg9gzjD3VXB8AA7?g_st=ic</a>
                        </p>
                        <p>
                            Voici l'adresse écrite au cas où vous auriez besoin d'indiquer un taxi :<br />
                            Tomtom, Kumbaracı Ykş. No:28, 34433 Beyoğlu, İstanbul.
                        </p>
                        <p>
                            C'est l'entrée du bâtiment, vous devez passer la première porte côté rue et prendre la première à droite, il y a une autre porte :
                        </p>
                        <div class="imageContainer noRatio">
                            <img src="/img/access-no28-Photo1-1.jpg" alt="Place detail" />
                        </div>
                        <p>
                            Ensuite, vous devez aller au 4ème étage et vous verrez une porte de meilleure facture sur votre gauche. C'est la porte de mon appartement. Il faut monter quelques marches avant que l'éclairage des escaliers ne s'allume.
                        </p>
                        <div class="imageContainer noRatio">
                            <img src="/img/access-no28-Photo2.jpg" alt="Place detail" />
                        </div>
                        <p>
                            La clé se trouve dans la boîte suivante :
                        </p>
                        <div class="imageContainer noRatio">
                            <img src="/img/keys_photo.jpg" alt="Place detail" />
                        </div>
                        <p>
                            Le code est le 1107.
                        </p>
                        <p>
                            Une fois que vous avez obtenu les clés, entrez un autre code aléatoire pour fermer la boîte.
                        </p>
                        <p>
                            Rappelez-vous que seules des familles locales vivent dans ce bâtiment. Si vous pouviez faire le moins de bruit possible lorsque vous portez les bagages afin que personne ne se plaigne auprès de moi.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            CLÉS
                        </div>
                        <p>
                            Vous disposez de deux paires de clés, veuillez les laisser sur la table en partant.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            WIFI
                        </div>
                        <p>
                            Nom : FiberHGW_ZTAF2A<br />
                            Mot de passe : zFDpdjuzDu
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            POUBELLES
                        </div>
                        <p>
                            Vous pouvez les déposer tard dans la nuit ou tôt le matin devant le bâtiment, le service de jour les ramassera.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            CONTACT
                        </div>
                        <p>
                            Si vous avez besoin de quelque chose qui n'est pas mentionné ici, n'hésitez pas à me contacter au <a href="tel:+90 532 218 49 42">+90 532 218 49 42</a> à n'importe quelle heure du jour ou de la nuit.
                        </p>
                        <p>
                            J'espère que vous apprécierez votre séjour ici.
                        </p>
                        <p>
                            Emrah Yüceer<br />
                            <a href="https://www.instagram.com/staywithalfred/" rel="noreferrer" target="_blank">@staywithalfred</a>
                        </p>
                    </div>
                </div>
                <div class="imageContainer" style="margin-bottom: 0;">
                    <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39516.jpg" alt="Place detail" />
                </div>`
            }
        }

    }
}