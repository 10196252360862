import './HomeSubmitted.page.css';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useMenu} from "../../MenuContext";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../Constants';
import {toast} from "react-toastify";
import {Circles} from "react-loader-spinner";

function showError(message) {
    toast.error(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}
function HomeSubmittedPage() {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    });

    const { updateBookingData } = useMenu();
    const [bookingData, setBookingData] = useState(null);

    const navigate = useNavigate()

    useEffect(() => {
        const bookingNumber = localStorage.getItem('bookingNumber');
        const token = localStorage.getItem('token');
        axios.get(`${API_BASE_URL}/booking/${bookingNumber}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            if(response.data.status === 6 || response.data.status === 0) {
                localStorage.clear();
                navigate('/');
                return;
            }
            if(response.data.status !== 5 && response.data.status !== 4) {
                navigate('/');
                return;
            }
            setBookingData(response.data);
            updateBookingData(response.data);
        }).catch(error => {
            console.log(error.response);
            if(error.response && error.response.status === 401) {
                showError(t('general.tokenError'));
                localStorage.clear();
                navigate('/login');
                return;
            }
            showError(t('general.unexpectedError'));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        !bookingData ? <div className={"pageLoader"}><Circles color={"#FCF8EC"} visible={true} /></div> : <div className={"homeSubmitContainer"}>
            <div className={"homeSubmitImageContainer"}>
                <img src={bookingData.housing.airbnbName !== 'No.28 Art Deco: Chic 2+1 Galata' ? "/img/20220621_ErdemHamza_Reisdere66118.jpg" : "/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39372.jpg"} alt={"Form success page"} />
            </div>
            <div className={"homeSubmitContent"}>
                <div className={"formContainer"}>
                    <div className={"title"} dangerouslySetInnerHTML={{__html: t('submitted.title')}}>
                    </div>
                    <div className={"description"}>
                        {t('submitted.description')}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeSubmittedPage;