module.exports = {
    translation: {
        general: {
            unexpectedError: "Unexpected error happened 🙄",
            guest: "Guest",
            tokenError: "Your session has been terminated, please log in again."
        },
        header: {
            dashboard: "DASHBOARD",
            access: "ACCESS INSTRUCTIONS",
            idDocuments: "ID DOCUMENTS",
            contact: "CONTACT",
            logout: "LOGOUT"
        },
        login: {
            booking: {
                notfound: "Booking number couldn't be found.",
                cancelled: "This booking has been cancelled.",
                past: "You cannot login because this reservation has been completed."
            },
            title: "<span>MANAGE</span> YOUR RESERVATION",
            description: "Enter your details to manage your reservation",
            placeholder: "Airbnb confirmation code",
            submit: "SUBMIT"
        },
        form: {
            noChange: "There is no change to submit 🙄",
            noGuest: "There is no guests to submit 🙄",
            firstNameError : "First Name field is required.",
            lastNameError: "Last Name field is required for guest ",
            phoneError: "Phone Number field is required for guest ",
            whatsappError: "WhatsApp Phone Number field is required for guest ",
            emailError: "Email field is required for guest ",
            emailValidError: "You need to enter valid email address for guest ",
            phoneValidError: "You need to enter valid phone number for guest ",
            whatsappValidError: "You need to enter valid WhatsApp phone number for guest ",
            passportError: "You need to upload a passport for guest ",
            checkinError: "You need to select check-in time for guest ",
            checkoutError: "You need to select check-out time for guest ",
            unexpectedError: "Unexpected error happened while trying to upload document for guest ",
            unexpectedError2: "Unexpected error happened while trying to submit guest ",
            success: "Form submitted successfully 👍",
            reservation: "Reservation",
            address: "Address",
            flat: "Flat",
            arrival: "Arrival",
            departure: "Departure",
            title: "THE<br />GUESTS",
            description: "It’s the law in Turkey to <span class='textBackground'>submit</span> <span class='textBackground'>your</span> <span class='textBackground'>ID</span> and those of <span class='textBackground'>all</span> <span class='textBackground'>the</span> <span class='textBackground'>people</span> <span class='textBackground'>staying</span> <span class='textBackground'>with</span> <span class='textBackground'>you</span> before the start of your stay.",
            descriptionSmall: "You will be able to access your reservation details once your identification documents are confirmed by our guest team.",
            descriptionSubmitted: "You have successfully submitted your guest list, it is currently under verification. You can add guests at any time by clicking on SUBMIT.",
            addGuest: "ADD A GUEST",
            submit: "SUBMIT",
            bottom: "If you have any question, you can reach us at <a href=\"mailto:reservations@staywithalfred.com\">reservations@staywithalfred.com</a>",
        },
        guest: {
            firstName: "First Name",
            lastName: "Family Name",
            phone: "Phone Number",
            phonePlaceholder: "eg. +1234567890",
            whatsapp: "WhatsApp Phone Number",
            whatsappPlaceholder: "eg. +1234567890",
            email: "Email",
            upload: "UPLOAD PASSPORT",
            selectedDocument: "Document selected:",
            mainGuest: "Main guest",
            guest: "Guest",
            checkin: "Check-in Time",
            checkout: "Check-out Time"
        },
        submitted: {
            title: "<span>YOUR ID</span> CONFIRMATION IS PENDING",
            description: "You will be able to access your reservation page once your identification documents are confirmed by our guest team.",
        },
        details: {
            title: "Hello",
            description: "Your <span class=\"textBackground\">ID</span> <span class=\"textBackground\">documents</span> are confirmed and you can manage your stay from this page.",
            access: "ACCESS INSTRUCTIONS",
            reservation: "Reservation",
            address: "Address",
            flat: "Flat",
            arrival: "Arrival",
            departure: "Departure",
            alacati: {
                address: "Alaçatı Stone Houses",
                content: `<div class="content-wrapper">
                    <div class="textContainer" style="text-align: left;">
                        <div class="subtitle">
                            Escape to a world of luxury and tradition in our two exquisite 130-year-old stone villas.
                        </div>
                        <p>
                            The first villa has one spacious bedroom, a fully equipped kitchen, and a charming study room. The second villa boasts three elegant bedrooms, three luxurious bathrooms, a cozy living room, two dining areas, and a fully equipped kitchen.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <img src="/img/20220621_ErdemHamza_Reisdere66306.jpg" alt="Place detail" />
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <div class="subtitle">
                            Both villas have been beautifully refurbished by a renowned architect, blending the historic charm of the original building with modern comfort and convenience.
                        </div>
                        <p>
                            Step inside this enchanting stone house, where rustic charm meets modern luxury. Upon entering, you are instantly captivated by the harmonious blend of natural stone walls, warm wooden flooring, and stylish, high-quality furnishings. The house boasts an array of cutting-edge amenities, including lightning-fast unlimited internet, centralized air conditioning, and floor heating, ensuring your utmost comfort in every season.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere66351.jpg" alt="Place detail" />
                        </div>
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere66347.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <p>
                            The spacious, open-concept kitchen is a culinary enthusiast's dream. Fitted with state-of-the-art appliances and adorned with exquisite Le Creuset cookware and utensils, this functional yet elegant space invites you to unleash your inner chef. Rich wooden cabinetry and gleaming marble countertops provide ample storage and workspace, while a large, inviting island serves as the perfect gathering spot for friends and family.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <div>
                            <img src="/img/20220621_ErdemHamza_Reisdere65976.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <p>
                            Natural light filters in through the numerous windows, enhancing the home's cozy ambiance. The living area, featuring plush seating and a statement fireplace, is the ideal place to unwind after a long day. The unlimited hot water supply guarantees a pampering experience in the luxurious spa-like bathrooms, complete with rain showers and a soaking tub in the main bedroom.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere65914.jpg" alt="Place detail" />
                        </div>
                        <div>
                            <img class="double" src="/img/20220621_ErdemHamza_Reisdere6636.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer" style="text-align: left;">
                        <div>
                            <p>
                                Throughout the house, tasteful decor and unique items effortlessly complement the stunning stone architecture, creating a serene and sophisticated retreat that beckons you to make yourself at home.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="imageContainer noBottomMargin">
                    <img src="/img/20220621_ErdemHamza_Reisdere66133.jpg" alt="Place detail" />
                </div>
                </div>`
            },
            no28: {
                address: "No.28 Art Deco",
                content: `<div class="content-wrapper">
                    <div class="textContainer">
                        Immerse yourself in the glamour of the jazz age at No.28, an Art Deco-inspired 2+1 flat near the iconic Galata Tower.
                    </div> 
                    <div class="imageContainer">
                        <div>
                            <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39388.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer">
                        Designed by renowned architect Erdem Hamza, this elegant sanctuary features opulent furnishings, and a captivating 1920s charm. 
                    </div> 
                    <div class="imageContainer">
                        <div>
                            <img class="double" src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39408.jpg" alt="Place detail" />
                        </div>
                        <div>
                            <img class="double" src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39480.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer">
                        Located in the heart of Istanbul's cultural scene, you're steps away from vibrant cafes, galleries, and historic landmarks. 
                    </div> 
                    <div class="imageContainer">
                        <div>
                            <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39516.jpg" alt="Place detail" />
                        </div>
                    </div>
                    <div class="textContainer">
                        Experience the allure and sophistication of a bygone era while enjoying the enchanting spirit of Istanbul in this stylish retreat.
                    </div>
                </div>
                <div class="imageContainer noBottomMargin">
                    <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39431.jpg" alt="Place detail" />
                </div>
                </div>`
            }
        },
        access: {
            alacati: {
                content: `<div class="content-wrapper">
                    <div class="textContainer">
                        Those are the full instructions for the house, if you could please read them before getting in the villa that would be great as there are some important notes such as absolutely closing the big umbrella at night as strong winds can break it.
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            ACCESS
                        </div>
                        <p>
                            This is the exact location:<br />
                            <a href="https://goo.gl/maps/g6oG2g19qgNC49WSA" target="_blank">https://goo.gl/maps/g6oG2g19qgNC49WSA</a>
                        </p>
                        <p>
                            You'll arrive in front of a beige car park double door, and that's the place. Either Mr Murat the gardener will open the door for you, or I will leave the keys inside the garden and will open the door for you from my remote access.
                        </p>
                        <p>
                            I'm available 24/24 on WhatsApp <a href="https://api.whatsapp.com/send?phone=905322184942" target="_blank" rel="noreferrer">+905322184942</a>, if you could please send me us message about 1 hour before arriving that would be great.
                        </p>
                    </div>
                    <div class="imageContainer">
                        <img src="/img/access-alacati.jpg" alt="Place detail" />
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            GARAGE DOOR
                        </div>
                        <p>
                            The automatic garage door can only be opened with the 2 black remotes that you were provided with. Do not try to push it manually as it will break.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            KEYS
                        </div>
                        <p>
                            You have 3 keys, the first two are for the small house on the left when you arrive, the last one is for the main house.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            WIFI
                        </div>
                        <p>
                            Name: EREN-REISDERE<br />
                            Password: 1a2b3c4d5e6f
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            A/C
                        </div>
                        <p>
                            If the power goes off, which happens quite often in this region, the A/C units will revert back to heating mode. You will need to tap on <b>"OPER MODE"</b> to change the operation mode back to the icon which looks like a snowflake.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            FIREPLACE
                        </div>
                        <p>
                            It is absolutely forbidden to use the fireplaces, they are for decorative purpose only.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            TOILETS
                        </div>
                        <p>
                            Do not put paper in the toilets, there is a bin next to each toilet, please do use the bin.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            LARGE UMBRELLA
                        </div>
                        <ul>
                            <li>To open the umbrella, you need to grab the fabric and start lifting it towards the exterior, without using the handle. Once the fabric has been lifted, you can then use the handle to push it towards the top until the small metal part in the umbrella is locked.</li>
                            <li>To close it, you need to unlock the small metal part from the handle and push it slightly up and then it will come down on its own.</li>
                            <li>Please never leave the umbrella open during strong winds or at night as it will break.</li>
                        </ul>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            OUTDOOR CAMERAS
                        </div>
                        <p>
                            There are 4 outdoor cameras which records are automatically erased after 7 days if no incident is reported. They are used for security and insurance purpose.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            EXTERIOR LIGHTS
                        </div>
                        <p>
                            The garden lights light up automatically around 8pm. However those are just a few of them. To turn on all of the garden lights, you need to press the round circular button which is on the wall in front of the bathroom to your right when you enter the main house.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            SPARE TOWELS AND SHEETS
                        </div>
                        <p>
                            All the spare necessities are stored in the wardrobe located in the empty area after the stairs of the kitchen's adjacent dining table.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            WATER/GAS REPLENISHMENT
                        </div>
                        <p>
                            In case the large water bottle under the water machine is empty or the gas tube under the SMEG stove, you can call <b>Kaya Market</b> <a href="tel:+902327170423">+902327170423</a> and they will deliver it for you. You can request for the bill to be added to the house owner Emrah Yüceer
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            CONTACT
                        </div>
                        <p>
                            If you need anything else that's not listed here, do not hesitate to reach me from <a href="tel:+90 532 218 49 42">+90 532 218 49 42</a> at any time of the day or night.
                        </p>
                        <p>
                            I hope you'll enjoy your time here.
                        </p>
                        <p>
                            Emrah Yüceer<br />
                            <a href="https://www.instagram.com/staywithalfred/" rel="noreferrer" target="_blank">@staywithalfred</a>
                        </p>
                    </div>
                </div>
                <div class="imageContainer" style="margin-bottom: 0;">
                    <img src="/img/20220621_ErdemHamza_Reisdere66235.jpg" alt="Place detail" />
                </div>`
            },
            no28: {
                content: `<div class="content-wrapper">
                    <div class="textContainer">
                        Those are the full instructions for your rental, if you could please read them before getting in that would be great!
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            ACCESS
                        </div>
                        <p>
                            This is the exact Google Maps location:<br />
                            <a href="https://maps.app.goo.gl/EoYg9gzjD3VXB8AA7?g_st=ic" target="_blank">https://maps.app.goo.gl/EoYg9gzjD3VXB8AA7?g_st=ic</a>
                        </p>
                        <p>
                            This is the written address in case you need to show a taxi:<br />
                            Tomtom, Kumbaracı Ykş. No:28, 34433 Beyoğlu, İstanbul.
                        </p>
                        <p>
                            This is the building entrance, you need to pass the first street side door and take the first right, there is another door:
                        </p>
                        <div class="imageContainer noRatio">
                            <img src="/img/access-no28-Photo1-1.jpg" alt="Place detail" />
                        </div>
                        <p>
                            Then you need to go to the 4th floor and you will see a nicer door on your left side. This is my flat’s door.<br /> 
                            You have to go up a few steps before the lighting on the stairs light up.
                        </p>
                        <div class="imageContainer noRatio">
                            <img src="/img/access-no28-Photo2.jpg" alt="Place detail" />
                        </div>
                        <p>
                            The key will be inside the following box:
                        </p>
                        <div class="imageContainer noRatio">
                            <img src="/img/keys_photo.jpg" alt="Place detail" />
                        </div>
                        <p>
                            Code is 1107.
                        </p>
                        <p>
                            Once you get the keys, put another random code to close the box. 
                        </p>
                        <p>
                            Please remember only local families live in this building, if you could do as little noise as possible when carrying the luggage that would be great so that nobody complains to me.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            KEYS
                        </div>
                        <p>
                            You have 2 pairs of keys, please leave them both on the table when leaving.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            WIFI
                        </div>
                        <p>
                            Name: FiberHGW_ZTAF2A<br />
                            Password: zFDpdjuzDu
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            GARBAGE
                        </div>
                        <p>
                            You can leave it late at night or early morning in front of the building, the day service will pick it up.
                        </p>
                    </div>
                    <div class="textContainer" style="text-align:left;">
                        <div class="subtitle">
                            CONTACT
                        </div>
                        <p>
                            If you need anything else that's not listed here, do not hesitate to reach me from <a href="tel:+90 532 218 49 42">+90 532 218 49 42</a> at any time of the day or night.
                        </p>
                        <p>
                            I hope you'll enjoy your time here.
                        </p>
                        <p>
                            Emrah Yüceer<br />
                            <a href="https://www.instagram.com/staywithalfred/" rel="noreferrer" target="_blank">@staywithalfred</a>
                        </p>
                    </div>
                </div>
                <div class="imageContainer" style="margin-bottom: 0;">
                    <img src="/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39516.jpg" alt="Place detail" />
                </div>`
            }
        }

    }
}