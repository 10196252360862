import './Home.page.css';
import {useEffect, useState} from "react";
import {Circles} from "react-loader-spinner";
import axios from "axios";
import {API_BASE_URL} from "../../Constants";
import {toast} from "react-toastify";
import HomeDetailsPage from "./HomeDetails.page";
import HomeFormPage from "./HomeForm.page";
import {useMenu} from "../../MenuContext";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function showError(message) {
    toast.error(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}
function HomePage() {

    const { t } = useTranslation();

    const { updateBookingData } = useMenu();
    const [bookingData, setBookingData] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const bookingNumber = localStorage.getItem('bookingNumber');
        const token = localStorage.getItem('token');
        axios.get(`${API_BASE_URL}/booking/${bookingNumber}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            if(response.data.status === 6 || response.data.status === 0) {
                localStorage.clear();
                navigate('/');
                return;
            }
            setBookingData(response.data);
            updateBookingData(response.data);
        }).catch(error => {
            console.log(error.response);
            if(error.response && error.response.status === 401) {
                showError(t('general.tokenError'));
                localStorage.clear();
                navigate('/login');
                return;
            }
            showError(t('general.unexpectedError'));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        !bookingData ? <div className={"pageLoader"}><Circles color={"#FCF8EC"} visible={true} /></div> : (
            (bookingData.status !== 4 && bookingData.status !== 5) ? <HomeDetailsPage bookingData={bookingData} /> : <HomeFormPage bookingData={bookingData} />
        )
    );

}

export default HomePage;