import './Footer.css';
import {Link, useNavigate} from "react-router-dom";
import {useMenu} from "../../MenuContext";
import {useTranslation} from "react-i18next";

function Footer() {

    const navigate = useNavigate();

    const { t } = useTranslation();

    const { bookingData } = useMenu();

    let logoutButton = null;
    if(!!localStorage.getItem('token')) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        logoutButton = (<a href={"#"} onClick={e => {
            e.preventDefault();
            localStorage.clear();
            navigate('/');
        }}>{t('header.logout')}</a>);
    }

    return (
        <footer>
            <div className={"footerLogoContainer"}>
                <img src={"/img/logo.png"} alt={"SWA Logo"} className={"footerLogoDesktop"} />
                <img src={"/img/logo.png"} alt={"SWA Logo"} className={"footerLogoMobile"} />
            </div>
            {
                !(bookingData && (bookingData.status !== 4 && bookingData.status !== 5)) ? (
                    <div className={"footerMenu"}>
                        <a href={"https://api.whatsapp.com/send?phone=905322184942"} target={"_blank"} rel={"noreferrer"}>{t('header.contact')}</a>
                        {logoutButton}
                    </div>
                ) : (
                    <div className={"footerMenu"}>
                        <Link to={"/"}>{t('header.dashboard')}</Link>
                        <Link to={"/access"}>{t('header.access')}</Link>
                        <Link to={"/documents"}>{t('header.idDocuments')}</Link>
                        <a href={"https://api.whatsapp.com/send?phone=905322184942"} target={"_blank"} rel={"noreferrer"}>{t('header.contact')}</a>
                        {logoutButton}
                    </div>
                )
            }
            <div className={"footerSeparator"}></div>
            <div className={"footerBottom"}>
                <div className={"footerLinks"} style={{display: "none"}}>

                    <a href={"https://google.com"}>Cookies Policy</a>
                    <a href={"https://google.com"}>Legal Terms</a>
                    <a href={"https://google.com"}>Privacy Policy</a>
                </div>
                <div className={"footerSocialIcons"}>
                    <a href={"https://www.instagram.com/staywithalfred/"} target={"_blank"} rel={"noreferrer"}>
                        <img src={"/img/social-instagram.svg"} style={{width: '14px', height: '14px'}} alt={"Social Instagram Icon"} />
                    </a>
                    <a href={"https://google.com"} style={{display: "none"}}>
                        <img src={"/img/social-facebook.svg"} style={{width: '8px', height: '14px'}} alt={"Social Facebook Icon"} />
                    </a>
                    <a href={"https://google.com"} style={{display: "none"}}>
                        <img src={"/img/social-twitter.svg"} style={{width: '15px', height: '12px'}} alt={"Social Twitter Icon"} />
                    </a>
                    <a href={"https://google.com"} style={{display: "none"}}>
                        <img src={"/img/social-linkedin.svg"} style={{width: '12px', height: '12px'}} alt={"Social Linkedin Icon"} />
                    </a>
                </div>
            </div>
        </footer>
    );

}

export default Footer;