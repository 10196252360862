import React, { createContext, useContext, useState } from 'react';

const MenuContext = createContext();

export function MenuProvider({ children }) {
    const [bookingData, setBookingData] = useState(localStorage.getItem('bookingData') ? JSON.parse(localStorage.getItem('bookingData')) : null);

    const updateBookingData = (val) => {
        localStorage.setItem('bookingData', JSON.stringify(val));
        setBookingData(val);
    };

    return (
        <MenuContext.Provider value={{ bookingData, updateBookingData }}>
            {children}
        </MenuContext.Provider>
    );
}

export function useMenu() {
    return useContext(MenuContext);
}