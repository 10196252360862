import './Login.page.css';
import SubmitButton from "../../components/submit-button/SubmitButton";
import {useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import {API_BASE_URL} from "../../Constants";
import {useNavigate} from "react-router-dom";
import {useMenu} from "../../MenuContext";
import {useTranslation} from "react-i18next";
function Login() {

    const { t } = useTranslation();

    const { updateBookingData } = useMenu();

    updateBookingData(null);

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [reservationNumber, setReservationNumber] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if(isLoading) return;

        setIsLoading(true);
        axios.post(`${API_BASE_URL}/login`, null, {
            headers: {
                'X-AUTH-TOKEN': reservationNumber
            }
        }).then((response) => {
            const token = response.data.token;
            axios.get(`${API_BASE_URL}/booking/${reservationNumber}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                if(response.data.status === 6) {
                    toast.error(t('login.booking.past'), {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setIsLoading(false);
                } else if (response.data.status === 0) {
                    toast.error(t('login.booking.cancelled'), {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setIsLoading(false);
                } else {
                    localStorage.setItem('token', token);
                    localStorage.setItem('bookingNumber', reservationNumber);
                    navigate('/');
                }
            }).catch(error => {
                console.log(error.response);
                toast.error(t('login.booking.notfound'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
        }).catch(error => {
            if(error.response && error.response.status === 401) {
                toast.error(t('login.booking.notfound'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else {
                toast.error(t('general.unexpectedError'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
            setIsLoading(false);
        });
    };

    const handleChange = (event) => {
        const input = event.target;
        const value = input.value;

        setReservationNumber(value.toUpperCase());
    }

    return (
        <div className={"loginContainer"}>
            <div className={"loginImageContainer"}>
                <img src={"/img/login-image-1.jpg"} alt={"Login page"} />
            </div>
            <div className={"loginContent"}>
                <div className={"formContainer"}>
                    <div className={"title"} dangerouslySetInnerHTML={{__html: t('login.title')}}></div>
                    <form className={"form"} onSubmit={handleSubmit}>
                        <div>{t('login.description')}</div>
                        <div className={"input"}>
                            <input type={"text"} required={true} onChange={handleChange} value={reservationNumber} />
                            <label>{t('login.placeholder')}</label>
                        </div>
                        <SubmitButton text={t('login.submit')} style={{marginTop: '20px'}} loading={isLoading} type={"submit"} />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;