import './HomeForm.page.css';
import Guest from "../../components/guest/Guest";
import SubmitButton from "../../components/submit-button/SubmitButton";
import {useEffect, useState} from "react";
import {Circles} from "react-loader-spinner";
import axios from "axios";
import {API_BASE_URL} from "../../Constants";
import {toast} from "react-toastify";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from 'moment';

function showError(message) {
    toast.error(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}
function HomeFormPage({bookingData = null}) {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [inProgress, setInProgress] = useState(false);

    const [guests, setGuests] = useState([]);
    const [waitingGuests, setWaitingGuests] = useState([]);

    const formatter = new Intl.DateTimeFormat('en', { month: 'short' });
    const dayFormatter = new Intl.DateTimeFormat('en', { day: '2-digit' });

    const [dataUpdated, setDataUpdated] = useState(false);

    const [editEnabled, setEditEnabled] = useState(false);

    useEffect(() => {
        setEditEnabled((bookingData.status === 4 || bookingData.status === 5));
    }, [bookingData]);

    useEffect(() => {
        let tempGuests = [];
        let tempWaitingGuests = [];
        bookingData.guests.forEach(function(item) {
            if(Object.keys(item).length > 1) {
                tempGuests.push(item);
            } else {
                tempWaitingGuests.push(item);
            }
        });
        if(tempGuests.length === 0 && tempWaitingGuests.length > 0) {
            let waitingGuest = tempWaitingGuests.shift();
            waitingGuest.expanded = true;
            tempGuests.push(waitingGuest);
        }

        if(tempGuests.length > 0) {
            let bookingCheckin = bookingData.checkIn;
            let bookingCheckout = bookingData.checkOut;

            let checkInMin = moment(bookingData.fromDate.split('T')[0], 'YYYY-MM-DD').hour(15).minute(0);
            let checkInMax = moment(bookingData.fromDate.split('T')[0], 'YYYY-MM-DD').hour(15).minute(0).add(1, 'days');
            if(bookingCheckin && moment(bookingCheckin.split('+')[0]).isBetween(checkInMin, checkInMax, null, '[]')) {
                tempGuests[0].checkin = moment(bookingCheckin.split('+')[0]);
            }

            let checkOutMin = moment(bookingData.toDate.split('T')[0], 'YYYY-MM-DD').subtract(1, 'days').hour(11).minute(0);
            let checkOutMax = moment(bookingData.toDate.split('T')[0], 'YYYY-MM-DD').hour(11).minute(0);
            if(bookingCheckout && moment(bookingCheckout.split('+')[0]).isBetween(checkOutMin, checkOutMax, null, '[]')) {
                tempGuests[0].checkout = moment(bookingCheckout.split('+')[0]);
            }
        }

        setGuests(tempGuests);
        setWaitingGuests(tempWaitingGuests);
    }, [bookingData]);

    const handleAddGuests = () => {
        if(waitingGuests.length === 0) return;
        let tempWaitingGuests = [...waitingGuests];
        let tempGuests = [...guests];

        let waitingGuest = tempWaitingGuests.shift();
        waitingGuest.expanded = true;
        tempGuests.push(waitingGuest);

        setGuests(tempGuests);
        setWaitingGuests(tempWaitingGuests);
    };

    const handleSave = async () => {

        setInProgress(true);

        if(!dataUpdated) {
            showError(t('form.noChange'))
            setInProgress(false);
            return;
        }

        if(guests.length === 0) {
            showError(t('form.noGuest'))
            setInProgress(false);
            return;
        }

        for(let i = 0; i < guests.length; i++) {
            let item = guests[i];

            if(!item.firstname) {
                showError(t('form.firstNameError'));
                setInProgress(false);
                return;
            }

            if(!item.lastname) {
                showError(t('form.lastNameError') + (item.firstname));
                setInProgress(false);
                return;
            }

            const isMainGuest = i === 0;

            if((!item.documents || item.documents.length === 0) && !item.file) {
                showError(t('form.passportError') + (item.firstname + ' ' + item.lastname));
                setInProgress(false);
                return;
            }

            if(isMainGuest) {

                if(!item.checkin) {
                    showError(t('form.checkinError') + (item.firstname + ' ' + item.lastname));
                    setInProgress(false);
                    return;
                }

                if(!item.checkout) {
                    showError(t('form.checkoutError') + (item.firstname + ' ' + item.lastname));
                    setInProgress(false);
                    return;
                }

                if(!item.phone) {
                    showError(t('form.phoneError') + (item.firstname + ' ' + item.lastname));
                    setInProgress(false);
                    return;
                }

                if(!item.whatsApp) {
                    showError(t('form.whatsappError') + (item.firstname + ' ' + item.lastname));
                    setInProgress(false);
                    return;
                }

                if(!item.email) {
                    showError(t('form.emailError') + (item.firstname + ' ' + item.lastname));
                    setInProgress(false);
                    return;
                }

                if(!isEmail(item.email)) {
                    showError(t('form.emailValidError') + (item.firstname + ' ' + item.lastname));
                    setInProgress(false);
                    return;
                }

                if(!isMobilePhone(item.phone)) {
                    showError(t('form.phoneValidError') + (item.firstname + ' ' + item.lastname));
                    setInProgress(false);
                    return;
                }
                if(!isMobilePhone(item.whatsApp)) {
                    showError(t('form.whatsappValidError') + (item.firstname + ' ' + item.lastname));
                    setInProgress(false);
                    return;
                }

            }
        }

        for(let i = 0; i < guests.length; i++) {
            let tempItem = {...guests[i]};

            if(i === 0) {
                //this is main guest, try to update checkin and checkout dates
                let checkin = tempItem.checkin;
                let checkout = tempItem.checkout;
                try {
                    await axios.patch(`${API_BASE_URL}/booking/${bookingData.bookingNumber}`, {
                        checkIn: checkin.format('YYYY-MM-DDTHH:mm'),
                        checkOut: checkout.format('YYYY-MM-DDTHH:mm')
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                } catch (error) {
                    console.log(error);
                    if(error.response && error.response.status === 401) {
                        showError(t('general.tokenError'));
                        localStorage.clear();
                        navigate('/login');
                        return;
                    }
                    showError(t('form.unexpectedError2') + (tempItem.firstname + ' ' + tempItem.lastname));
                    setInProgress(false);
                    return;
                }
            }

            try {
                await axios.patch(`${API_BASE_URL}/booking/${bookingData.bookingNumber}/guest/${tempItem.id}`, {
                    firstname: tempItem.firstname,
                    lastname: tempItem.lastname,
                    phone: tempItem.phone,
                    whatsApp: tempItem.whatsApp,
                    email: tempItem.email
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });

                if(tempItem.file) {
                    const formData = new FormData();
                    formData.append('file', tempItem.file);

                    try {
                        await axios.post(`${API_BASE_URL}/guest/${tempItem.id}/document`, formData, {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        });
                    } catch (e) {
                        console.log(e);
                        if(e.response && e.response.status === 401) {
                            showError(t('general.tokenError'));
                            localStorage.clear();
                            navigate('/login');
                            return;
                        }
                        showError(t('form.unexpectedError') + (tempItem.firstname + ' ' + tempItem.lastname));
                        setInProgress(false);
                        return;
                    }
                }
            } catch (error) {
                console.log(error);
                if(error.response && error.response.status === 401) {
                    showError(t('general.tokenError'));
                    localStorage.clear();
                    navigate('/login');
                    return;
                }
                showError(t('form.unexpectedError2') + (tempItem.firstname + ' ' + tempItem.lastname));
                setInProgress(false);
                return;
            }
        }

        toast.success(t('form.success'), {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
        navigate('/submit');
    };

    return (
        !bookingData ? <div className={"pageLoader"}><Circles color={"#FCF8EC"} visible={true} /></div> : <div className={"homePage"}>
            <section className={"bannerSection"}>
                <img src={bookingData.housing.airbnbName !== 'No.28 Art Deco: Chic 2+1 Galata' ? "/img/20220621_ErdemHamza_Reisdere66118.jpg" : "/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39372.jpg"} alt={"Place"} />
                <div className={"content-wrapper infoBoxContainer"}>
                    <div className={"infoBox"}>
                        <div className={"item"}>
                            <div className={"title"}>{t('form.reservation')}</div>
                            <div className={"content"}>{bookingData.bookingNumber}</div>
                        </div>
                        <div className={"item"}>
                            <div className={"title"}>{t('form.flat')}</div>
                            <div className={"content"}>{bookingData.housing.airbnbName !== 'No.28 Art Deco: Chic 2+1 Galata' ? t('details.alacati.address') : t('details.no28.address')}</div>
                        </div>
                        <div className={"item-double"}>
                            <div className={"item"}>
                                <div className={"title"}>{t('form.arrival')}</div>
                                <div className={"content"}>{formatter.format(new Date(bookingData.fromDate))}. {dayFormatter.format(new Date(bookingData.fromDate))}</div>
                            </div>
                            <div className={"item"}>
                                <div className={"title"}>{t('form.departure')}</div>
                                <div className={"content"}>{formatter.format(new Date(bookingData.toDate))}. {dayFormatter.format(new Date(bookingData.toDate))}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={"formSection"}>
                <div className={"content-wrapper"}>
                    <h1 className={"title"} dangerouslySetInnerHTML={{__html: t('form.title')}}></h1>
                    <div className={"content"}>
                        <div className={"description"}>
                            <p dangerouslySetInnerHTML={{__html: t('form.description')}}>

                            </p>
                            <p className={"small"}>
                                {t('form.descriptionSmall')}
                            </p>
                            {
                                bookingData.status === 5 && <p className={"small"}>
                                    {t('form.descriptionSubmitted')}
                                </p>
                            }
                        </div>
                        <div>
                            {
                                (() => {
                                    let items = [];
                                    for(let i = 0; i < guests.length; i++) {
                                        items.push(<Guest key={"guest-" + guests[i].id} data={guests[i]} isMain={i === 0} editEnabled={editEnabled} expanded={!!guests[i].expanded} bookingData={bookingData} onChange={(data) => {
                                            let tempGuests = [...guests];
                                            tempGuests[i] = data;
                                            setGuests(tempGuests);
                                            setDataUpdated(true);
                                        }} />)
                                    }
                                    return items;
                                })()
                            }
                            {
                                editEnabled && waitingGuests.length > 0 && <div style={{textAlign: 'center'}}>
                                    <div className={"addGuestButton"} onClick={handleAddGuests}>
                                        <img src={"/img/add-guest-icon.svg"} alt={"Add guest"} />
                                        {t('form.addGuest')}
                                    </div>
                                </div>
                            }
                            {
                                editEnabled && <SubmitButton text={t('form.submit')} loading={inProgress} style={{marginTop: '60px'}} onClick={handleSave} />
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default HomeFormPage;