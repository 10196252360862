import './Header.css';
import {useRef} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useMenu} from "../../MenuContext";
import {useTranslation} from "react-i18next";

const languages = [
    {
        code: "en", native: "EN"
    },
    {
        code: "fr", native: "FR"
    },
    {
        code: "tr", native: "TR"
    }
]

function Header() {

    const navigate = useNavigate();

    const { bookingData } = useMenu();

    const { t, i18n } = useTranslation();
    
    const headerRef = useRef(null);

    const handleMenuButtonClick = () => {
        if(headerRef.current) {
            const header = headerRef.current;
            header.classList.toggle('menuActive');
            document.getElementsByTagName('body')[0].classList.toggle('menuActive');
        }
    }

    const handleMobileMenuItemClick = () => {
        if(headerRef.current) {
            const header = headerRef.current;
            header.classList.remove('menuActive');
            document.getElementsByTagName('body')[0].classList.remove('menuActive');
        }
    }

    return (
        <header ref={headerRef} className={"header"}>
            <div className={"headerContainer"}>
                <div className={"headerLeftButtons"}>
                    {
                        (bookingData && (bookingData.status !== 4 && bookingData.status !== 5)) && <button onClick={handleMenuButtonClick} className={"menuButton"}>
                            <div className={"nav-icon"}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    }
                </div>
                <div className={"headerContent"}>
                    {
                        !(bookingData && (bookingData.status !== 4 && bookingData.status !== 5)) ? <ul></ul> : (
                            <ul>
                                <li>
                                    <NavLink to={"/"}>
                                        {t('header.dashboard')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/access"}>
                                        {t('header.access')}
                                    </NavLink>
                                </li>
                            </ul>
                        )
                    }
                    <Link to={"/"}>
                        <img src={"/img/logo.png"} alt={"SWA Logo"} />
                    </Link>
                    {
                        !(bookingData && (bookingData.status !== 4 && bookingData.status !== 5)) ? <ul></ul> : (
                            <ul>
                                <li>
                                    <NavLink to={"/documents"}>
                                        {t('header.idDocuments')}
                                    </NavLink>
                                </li>
                                <li>
                                    <a href={"https://api.whatsapp.com/send?phone=905322184942"} target={"_blank"} rel={"noreferrer"}>
                                        {t('header.contact')}
                                    </a>
                                </li>
                            </ul>
                        )
                    }
                </div>
                <div className={"headerRightButtons"}>
                    <a className={"headerSocialButton"} href="https://www.instagram.com/staywithalfred/" target="_blank" rel="noreferrer"><img src="/img/social-instagram.svg" alt="Social Instagram Icon" /></a>
                    <div className={"languageButton"} onClick={(e) => {
                        e.target.classList.toggle('active');
                    }}>
                        { languages.find(i => i.code === (i18n.language.split('-')[0]))?.native }
                        <ul>
                            {
                                (() => {
                                    let items = [];
                                    languages.filter(i => i.code !== i18n.language.split('-')[0]).forEach(i => {
                                        items.push(<li key={"lang-" + i.code} onClick={() => {
                                            document.querySelector('.languageButton').classList.remove('active');
                                            i18n.changeLanguage(i.code);
                                            let url = new URL(window.location);
                                            url.searchParams.delete('lng');
                                            url.searchParams.append('lng', i.code);
                                            window.history.replaceState('', '', url.toString());
                                        }}>{i.native}</li>);
                                    });
                                    return items;
                                })()
                            }
                        </ul>
                    </div>
                    {
                        !!localStorage.getItem('token') && <button className={"logoutButton"} onClick={e => {
                            e.preventDefault();
                            localStorage.clear();
                            navigate('/');
                        }}><svg fill={"#FCF8EC"} style={{width: '15px', height: '15px', transform: 'rotate(180deg)'}} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M0 0h24v24H0z" fill="none"/><path d="M4 18h2v2h12V4H6v2H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3zm2-7h7v2H6v3l-5-4 5-4v3z"/></g></svg></button>
                    }

                </div>
            </div>
            <div className={"headerMobileLogo"}>
                <Link to={"/"}>
                    <img src={"/img/logo.png"} alt={"SWA Logo"} />
                </Link>
            </div>
            {
                (bookingData && (bookingData.status !== 4 && bookingData.status !== 5)) && <div className={"mobileMenu"}>
                    <ul>
                        <li>
                            <NavLink to={"/"} onClick={handleMobileMenuItemClick}>
                                {t('header.dashboard')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/access"} onClick={handleMobileMenuItemClick}>
                                {t('header.access')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/documents"} onClick={handleMobileMenuItemClick}>
                                {t('header.idDocuments')}
                            </NavLink>
                        </li>
                        <li>
                            <a href={"https://api.whatsapp.com/send?phone=905322184942"} target={"_blank"} rel={"noreferrer"} onClick={handleMobileMenuItemClick}>
                                {t('header.contact')}
                            </a>
                        </li>
                    </ul>
                </div>
            }
        </header>
    );
}

export default Header;