import './SubmitButton.css';
import { ThreeDots } from  'react-loader-spinner';

function SubmitButton({text = 'SUBMIT', required = false, icon = null, onClick, style={}, loading = false, type = "button"}) {
    return (<button style={style} className={"customButton"} onClick={onClick} type={type}>{!loading ? (required ? [<div key={"asd1"}>{icon}</div>, <div key={"asd"} style={{marginTop: '4px'}}>{text}<span key={"asd2"} style={{color: '#ff0000'}}>*</span></div>] : [<div key={"qwe1"}>{icon}</div>, <div key={"qwe"} style={{marginTop: '4px'}}>{text}</div>]) : (
        <ThreeDots
            height="30"
            width="30"
            radius="9"
            color="#FFF"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
        />
    )}</button>);
}

export default SubmitButton;