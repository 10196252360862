import './App.css';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import {Route, Routes, useLocation} from "react-router-dom";
import Login from "./pages/login/Login.page";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from "./components/private-route/PrivateRoute";
import HomePage from "./pages/home/Home.page";
import AccessReisderePage from "./pages/access/Access.reisdere.page";
import HomeSubmittedPage from "./pages/home/HomeSubmitted.page";
import HomeIdDocumentsPage from "./pages/home/HomeIdDocuments.page";
import {MenuProvider} from "./MenuContext";
import {
    TransitionGroup,
    CSSTransition
} from "react-transition-group";

import "./packages/react-router-dom/Animation/styles.css";
import { useRef } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/tr';
import { useTranslation } from 'react-i18next';

function App() {
    let location = useLocation();

    const nodeRef = useRef(null);

    const { i18n } = useTranslation();

    return (
    <div className={"mainContent"}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language.startsWith('tr') ? 'tr' : (i18n.language.startsWith('fr') ? 'fr' : 'en-gb')}>
            <MenuProvider>
                <Header />
                <div className="App">
                    <TransitionGroup>
                        <CSSTransition
                            key={location.pathname}
                            classNames="fade"
                            timeout={300}
                            nodeRef={nodeRef}
                        >
                            <div ref={nodeRef}>
                                <Routes>
                                    <Route exact path="/access" element={<PrivateRoute component={AccessReisderePage} />} />
                                    <Route exact path="/submit" element={<PrivateRoute component={HomeSubmittedPage} />} />
                                    <Route exact path="/documents" element={<PrivateRoute component={HomeIdDocumentsPage} />} />
                                    <Route exact path="/" element={<PrivateRoute component={HomePage} />} />
                                    <Route path="/login" element={<Login />} />
                                </Routes>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                <Footer />
                <ToastContainer />
            </MenuProvider>
        </LocalizationProvider>
    </div>
    );
}

export default App;
