import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translation as enTranslation } from './languages/en';
import { translation as trTranslation } from './languages/tr';
import { translation as frTranslation } from './languages/fr';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // language resources
        resources: {
            en: {
                translation: enTranslation
            },
            tr: {
                translation: trTranslation
            },
            fr: {
                translation: frTranslation
            },
        }
    });

document.documentElement.lang = i18n.language;
i18n.on('languageChanged', (lng) => {document.documentElement.setAttribute('lang', lng);})

export default i18n;