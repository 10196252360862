import {useRef, useState} from "react";
import './Guest.css';
import SubmitButton from "../submit-button/SubmitButton";
import DocumentPreview from "../document/Document";
import DocumentSelectedPrview from "../document/DocumentSelected";
import {useTranslation} from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

function Guest({data = {}, bookingData = {}, editEnabled = false, isMain = false, onChange = null, expanded = false, ...props}) {

    const fileInputRef = useRef();

    const { t } = useTranslation();

    const [isExpanded, setIsExpanded] = useState(expanded);
    const [firstName, setFirstName] = useState(data.firstname ?? '');
    const [lastName, setLastName] = useState(data.lastname ?? '');
    const [phoneNumber, setPhoneNumber] = useState(data.phone ?? '');
    const [whatsappNumber, setWhatsappNumber] = useState(data.whatsApp ?? '');
    const [email, setEmail] = useState(data.email ?? '');
    const [selectedFile, setSelectedFile] = useState(null);

    const [checkInDate, setCheckInDate] = useState(data.checkin ?? null);
    const [checkOutDate, setCheckOutDate] = useState(data.checkout ?? null);

    return (<div className={"guest" + (isExpanded ? ' expanded' : '')}>
        <div className={"titleContainer"} onClick={e => setIsExpanded(!isExpanded)}>
            <div className={"nameContainer"}>
                <div className={"name"}>{data.firstname && data.lastname ? data.firstname + ' ' + data.lastname : (data.firstname ? data.firstname : (data.lastname ? data.lastname : 'Guest Name'))}</div>
                <div className={"guestType" + (isMain ? ' main' : '')}>{isMain ? t('guest.mainGuest') : t('guest.guest')}</div>
            </div>
            <img src={"/img/arrow-down.svg"} className={"expandIcon"} alt={"Expand"} />
        </div>
        <div className={"form"}>
            <div className={"line"}>
                <div className={"lineItem"}>
                    <div className={"name"}>{t('guest.firstName')}<span>*</span></div>
                    <input readOnly={!editEnabled} className={"value"} value={firstName} onChange={e => {
                        setFirstName(e.target.value);
                        let cloneData = {...data};
                        cloneData.firstname = e.target.value;
                        onChange && onChange(cloneData);
                    }} placeholder={t('guest.firstName')} />
                </div>
                <div className={"lineItem"}>
                    <div className={"name"}>{t('guest.lastName')}<span>*</span></div>
                    <input readOnly={!editEnabled} className={"value"} value={lastName} onChange={e => {
                        setLastName(e.target.value);
                        let cloneData = {...data};
                        cloneData.lastname = e.target.value;
                        onChange && onChange(cloneData);
                    }} placeholder={t('guest.lastName')} />
                </div>
            </div>
            <div className={"line"}>
                <div className={"lineItem"}>
                    <div className={"name"}>{t('guest.phone')}{isMain && <span>*</span>}</div>
                    <input readOnly={!editEnabled} className={"value"} type={"tel"} value={phoneNumber} onChange={e => {
                        setPhoneNumber(e.target.value);
                        let cloneData = {...data};
                        cloneData.phone = e.target.value;
                        onChange && onChange(cloneData);
                    }} placeholder={t('guest.phonePlaceholder')} />
                </div>
                <div className={"lineItem"}>
                    <div className={"name"}>{t('guest.whatsapp')}{isMain && <span>*</span>}</div>
                    <input readOnly={!editEnabled} className={"value"} type={"tel"} value={whatsappNumber} onChange={e => {
                        setWhatsappNumber(e.target.value);
                        let cloneData = {...data};
                        cloneData.whatsApp = e.target.value;
                        onChange && onChange(cloneData);
                    }} placeholder={t('guest.whatsappPlaceholder')} />
                </div>
            </div>
            <div className={"line"}>
                <div className={"lineItem"}>
                    <div className={"name"}>{t('guest.email')}{isMain && <span>*</span>}</div>
                    <input readOnly={!editEnabled} className={"value"} type={"email"} value={email} onChange={e => {
                        setEmail(e.target.value);
                        let cloneData = {...data};
                        cloneData.email = e.target.value;
                        onChange && onChange(cloneData);
                    }} placeholder={t('guest.email')} />
                </div>
            </div>
            { isMain && <div className="line">
                <div className="lineItem">
                    <div className="name">{t('guest.checkin')}{isMain && <span>*</span>}</div>
                    <MobileDateTimePicker
                        className="dateTimePicker"
                        renderInput={(props) => <input {...props} />}
                        value={checkInDate}
                        onChange={(newValue) => {
                            setCheckInDate(newValue);
                            let cloneData = {...data};
                            cloneData.checkin = newValue;
                            onChange && onChange(cloneData);
                        }}
                        readOnly={!editEnabled}
                        minDateTime={moment(bookingData.fromDate.split('T')[0], 'YYYY-MM-DD').hour(15).minute(0)}
                        maxDateTime={moment(bookingData.fromDate.split('T')[0], 'YYYY-MM-DD').hour(15).minute(0).add(1, 'days')}
                        format="DD/MM/YYYY HH:mm"
                        localeText={{toolbarTitle: t('guest.checkin')}}
                    />
                </div>
                <div className="lineItem">
                    <div className="name">{t('guest.checkout')}{isMain && <span>*</span>}</div>
                    <MobileDateTimePicker
                        className="dateTimePicker"
                        renderInput={(props) => <input {...props} />}
                        value={checkOutDate}
                        onChange={(newValue) => {
                            setCheckOutDate(newValue);
                            let cloneData = {...data};
                            cloneData.checkout = newValue;
                            onChange && onChange(cloneData);
                        }}
                        readOnly={!editEnabled}
                        minDateTime={moment(bookingData.toDate.split('T')[0], 'YYYY-MM-DD').subtract(1, 'days').hour(11).minute(0)}
                        maxDateTime={moment(bookingData.toDate.split('T')[0], 'YYYY-MM-DD').hour(11).minute(0)}
                        format="DD/MM/YYYY HH:mm"
                        localeText={{toolbarTitle: t('guest.checkout')}}
                    />
                </div>
            </div> }
            <div>
                {
                    data.documents && data.documents.map((item, index) => <DocumentPreview key={"file-" + index} filename={item} guestId={data.id} />)
                }
            </div>
            {
                editEnabled && <div className={"line"} style={{marginTop: '10px'}}>
                    {
                        !selectedFile && <div className={"lineItem"}>
                            <SubmitButton
                                icon={<img src={"/img/upload-icon.svg"} alt={"Upload"} style={{margin: '0'}} />}
                                text={t('guest.upload')}
                                required={true}
                                style={{height: '50px', padding: '10px', fontSize: '16px', letterSpacing: '1.6px'}}
                                onClick={e => {
                                    fileInputRef.current.click();
                                }}/>
                            <input type={"file"} ref={fileInputRef} style={{display: "none"}} accept={"image/*,application/pdf"} onChange={e => {
                                if (!e.target.files || e.target.files.length === 0) {
                                    let cloneData = {...data};
                                    cloneData.file = null;
                                    onChange && onChange(cloneData);
                                    setSelectedFile(null)
                                    return;
                                }

                                let cloneData = {...data};
                                cloneData.file = e.target.files[0];
                                onChange && onChange(cloneData);
                                setSelectedFile(e.target.files[0]);
                            }} />
                        </div>
                    }
                    {
                        selectedFile && <div className={"filePreview"}>
                            <DocumentSelectedPrview file={selectedFile} />
                        </div>
                    }
                </div>
            }
        </div>
    </div>);
}

export default Guest;