import {useEffect, useState, useLayoutEffect, useRef} from "react";
import {ThreeDots} from "react-loader-spinner";
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import useResizeObserver from '@react-hook/resize-observer';

const useWidth = (target) => {
    const [width, setWidth] = useState(null);

    useLayoutEffect(() => {
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);

    useResizeObserver(target, (entry) => setWidth(entry.contentRect.width));
    return width;
};

function DocumentSelectedPrview({file}) {

    const [objectUrl, setObjectUrl] = useState(null);

    const wrapperDiv = useRef(null);
    const width = useWidth(wrapperDiv);

    useEffect(() => {
        if(!file) return;
        const objectUrl = URL.createObjectURL(file);
        setObjectUrl(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [file]);

    let resp;

    if(!file) {
        resp = (
            <div className={"filePreviewLoading"}>
                <ThreeDots
                    height="30"
                    width="30"
                    radius="9"
                    color="#FFF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
        )
    } else {
        resp = (<div className={"filePreview document"}>
            {
                file.type.match('image/*') && <img src={objectUrl} alt={"Document uploaded before"} onClick={e => {
                    window.open(objectUrl, '_blank');
                }} />
            }
            {
                file.type.match('application/pdf') && <Document file={file} renderMode={"canvas"} onClick={() => {
                    window.open(objectUrl, '_blank');
                }}>
                    <Page pageNumber={1} width={width} />
                </Document>
            }
        </div>);
    }

    return (
        <div ref={wrapperDiv} className="wrapper">
            {resp}
        </div>
    )

}

export default DocumentSelectedPrview;