import './Access.page.css';
import {useTranslation} from "react-i18next";
import {useMenu} from "../../MenuContext";

function AccessReisderePage() {

    const { bookingData } = useMenu();

    const { t } = useTranslation();

    return (
        <div className={"accessPage"}>
            <section className={"bannerSection"}>
                <img src={bookingData.housing.airbnbName !== 'No.28 Art Deco: Chic 2+1 Galata' ? "/img/20220621_ErdemHamza_Reisdere66118.jpg" : "/img/20230424_Erdem_Hamza_Kumbaraci_Yokusu39372.jpg"} alt={"Place"} />
            </section>
            <section className={"contentSection"} dangerouslySetInnerHTML={{__html: bookingData.housing.airbnbName !== 'No.28 Art Deco: Chic 2+1 Galata' ? t('access.alacati.content') : t('access.no28.content')}}></section>
        </div>
    );

}

export default AccessReisderePage;