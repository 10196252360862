import {useEffect, useState, useLayoutEffect, useRef} from "react";
import axios from "axios";
import {API_BASE_URL} from "../../Constants";
import {ThreeDots} from "react-loader-spinner";
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import useResizeObserver from '@react-hook/resize-observer';

const useWidth = (target) => {
    const [width, setWidth] = useState(null);

    useLayoutEffect(() => {
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);

    useResizeObserver(target, (entry) => setWidth(entry.contentRect.width));
    return width;
};

function DocumentPreview({filename, guestId}) {

    const [file, setFile] = useState(null);
    const [fileBlob, setFileBlob] = useState(null);

    const wrapperDiv = useRef(null);
    const width = useWidth(wrapperDiv);

    useEffect(() => {axios.get(`${API_BASE_URL}/guest/${guestId}/document/${filename}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            responseType: 'blob'
        }).then(async (response) => {
            setFileBlob(response.data);
            const reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = function() {
                setFile(reader.result);
            }
        });
    }, [filename, guestId]);

    let resp = '';

    if(!file) {
        resp = (
            <div className={"filePreviewLoading"}>
                <ThreeDots
                    height="30"
                    width="30"
                    radius="9"
                    color="#FFF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
        )
    } else {
        resp = (<div className={"filePreview document"}>
            {
                fileBlob.type.match('image/*') && <img src={file} alt={"Document uploaded before"} onClick={e => {
                    const fileUrl = URL.createObjectURL(fileBlob);
                    window.open(fileUrl, '_blank');
                }} />
            }
            {
                fileBlob.type.match('application/pdf') && <Document file={new File([fileBlob], "test.pdf")} renderMode={"canvas"} onClick={() => {
                    const fileUrl = URL.createObjectURL(fileBlob);
                    window.open(fileUrl, '_blank');
                }}>
                    <Page pageNumber={1} width={width} />
                </Document>
            }
        </div>);
    }

    return (
        <div ref={wrapperDiv} className="wrapper">
            {resp}
        </div>
    )

}

export default DocumentPreview;